$(function () {
  $('.dropdown-toggle').dropdown();
  $('.selectpicker').selectpicker({
    liveSearch: true
  }).ajaxSelectPicker({
    ajax: {
      url: '/search.php',
      data: function data() {
        return {
          q: '{{{q}}}'
        };
      }
    },
    locale: {
      currentlySelected: 'Aktualnie wybrany',
      emptyTitle: 'Wyszukaj punkt pobrań',
      errorText: 'Nie można wyszukać punktów pobrań',
      searchPlaceholder: 'Szukaj...',
      statusInitialized: 'Zacznij pisać szukane wyrażenie',
      statusNoResults: 'Nic nie znaleziono',
      statusSearching: 'Szukam...',
      statusTooShort: 'Wpisz więcej znaków'
    },
    preprocessData: function preprocessData(data) {
      var collectionPoints = [];
      var len = data.length;
      for (var i = 0; i < len; i++) {
        var curr = data[i];
        collectionPoints.push({
          'value': curr.symbol,
          'text': curr.name
        });
      }
      return collectionPoints;
    }
  });
  $('button').on('click', function (e) {
    var target = $(this).data('target');
    if (target) {
      $(this).parents('.page').addClass('hidden');
      $('#' + target).removeClass('hidden');
    }
  });
  $('.cookie-alert').find('button').on('click', function (e) {
    $('.cookie-alert').hide();
    // $('.cookie-alert').animate({
    //     'top': '100%',
    // }, 400);
  });
});